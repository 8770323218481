import axios, { AxiosResponse } from 'axios';
import { FC, useState, useEffect } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Form, Formik, FormikValues, Field } from 'formik'
import { Section, sectionInit, ValidationSchema } from '../../models/Section'
import { ViewSection } from '../../modules/components/display/ViewSection';
import { ErrorDisplay } from '../../modules/components/common/ErrorDisplay';
import { DisplayLoader } from '../../utils/loader';
import { number } from 'yup';
import { useAuth } from '../../modules/auth';
import { Modal } from 'react-bootstrap';
import { getUserByToken } from '../../modules/auth/core/_requests';
import { systemSort } from '../../utils/systemSort';
import { Registration } from '../registration/Registration';
import { initializeApp } from "firebase/app";
import { getDatabase, ref, set, onValue } from "firebase/database";
import KaTeXRenderer from '../utils/KatexRenderer';
import DragDrop from '../../modules/components/display/displayquestiontypes/DragAndDrop';
import SequenceVertical from '../../modules/components/display/displayquestiontypes/SequenceVertical';
import SequenceHorizontal from '../../modules/components/display/displayquestiontypes/SequenceHorizontal';
const firebaseConfig = {
  apiKey: "AIzaSyByPMDxjiibLupiXvlbubCSlsabPFurpXA",
  authDomain: "olearno-4b1fb.firebaseapp.com",
  databaseURL: "https://olearno-4b1fb-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "olearno-4b1fb",
  storageBucket: "olearno-4b1fb.appspot.com",
  messagingSenderId: "990749151543",
  appId: "1:990749151543:web:8652bce69adc94c82acab2",
  measurementId: "G-PY2RTFHBE3"
};
const app = initializeApp(firebaseConfig);
const database = getDatabase(app);
type ApiParams = { id: string; sectionNo: string };
const PresentLive: FC = () => {
  const { id, sectionNo } = useParams<ApiParams>();
  const { saveAuth, currentUser, setCurrentUser } = useAuth()
  const navigate = useNavigate();
  let [isLoading, setIsLoading] = useState<boolean>(true);
  let [challenge, setChallenge] = useState<any>({ sections: [] });
  let [copyStatus, setCopyStatus] = useState<boolean>(false);
  let [section, setSection] = useState<any>({});
  let [showRegistrationForm, setShowRegistrationForm] = useState<boolean>(false);
  let [attempt, setAttempt] = useState<any>({});
  let [attempts, setAttempts] = useState<any>([]);
  let [attemptId, setAttemptId] = useState<any>('');
  let [sessionId, setSessionId] = useState<string>('');
  let [currentSectionNo, setCurrentSectionNo] = useState<number>(-1);
  let [initValues, setInitValues] = useState<Section>(sectionInit);
  let [validationSchema, setValidationSchema] = useState<any>({});
  const isMatch = (index: any, category: any, answer: any, options: any) => {
    let ans = answer.filter((a: any) => { return a.split('-')[0] == index })[0]
    if (ans) {
      let ansRight = ans.split('-')[1]
      return category == options[ansRight][1]
    }
  };
  useEffect(() => {
    if (currentSectionNo !== -1) {
      if (document.getElementById('section')) {
        (document.getElementById('section') as HTMLElement).style.display = 'none'
      }
      if (document.getElementById('footer')) {
        (document.getElementById('footer') as HTMLElement).style.display = 'none'
      }
      getSection();
    }
  }, [currentSectionNo])
  useEffect(() => {
    if (sectionNo && sectionNo !== '') {
      setCurrentSectionNo(parseInt(sectionNo));
    } else {
      setCurrentSectionNo(0);
    }
  }, []);
  const showThis = () => {
    let sessionIdNew = sessionId
    if (sessionId == '') sessionIdNew = Date.now().toString()
    setSessionId(sessionIdNew)
    const variableRef = ref(database, 'live/' + currentUser?.id);
    set(variableRef, {
      sessionId: sessionIdNew,
      challengeId: id,
      sectionPosition: currentSectionNo
    })
      .then(() => {
        console.log(`Value updated successfully`);
      })
      .catch((error) => {
        console.error("Error updating value:", error);
      });
  }
  const updateRegistrationValues = async (values: FormikValues) => {
    console.log("here")
    let input: any = { name: values.name, institutionId: challenge.institutionId, role: 'student' }
    let profileDetails = { ...values }
    delete profileDetails.name;
    input.profileDetails = profileDetails
    try {
      const { data: auth } = await axios.post(process.env.REACT_APP_API_URL + '/create-user-without-login/', input)
      console.log(auth)
      saveAuth(auth)
      const { data: user } = await getUserByToken(auth.api_token)
      setCurrentUser(user)
      // getSection();
    } catch (e) {
      //TODO
    }


  }
  const updateValidationSchema = (values: Section) => {
    setValidationSchema(ValidationSchema(values, true));
  }
  const getReports = () => {
    axios.get(process.env.REACT_APP_API_URL + '/get-live-reports/' + id + '/' + sessionId,
    ).then((reply) => { setAttempts(reply.data) })
  }
  const getSection = (shouldNotReload?: boolean) => {
    DisplayLoader(true, '')
    if (!shouldNotReload) setIsLoading(true);
    axios.post(process.env.REACT_APP_API_URL + '/get-section/',
      { challengeInstanceId: id, type: 'lesson', sectionPosition: currentSectionNo }).then((reply) => {
        console.log(reply.data)
        if (reply?.data) {
          if (reply.data.register) {
            setChallenge(reply.data.challenge);
            setShowRegistrationForm(true)
          } else if (reply.data.redirect) {
            window.location.pathname = reply.data.redirect;
          } else {
            setShowRegistrationForm(false);
            if (reply.data.section?.type === 'pair') {
              reply.data.section.jumbledOptions = systemSort([...reply.data.section.options]);
            }
            console.log(reply.data)
            setChallenge(reply.data.challenge);
            setSection(reply.data.section);
            setAttempt(reply.data.sectionAttempt);
            setAttemptId(reply.data.attemptId);
            if (reply.data.sectionAttempt && !reply.data.sectionAttempt.answer && !(reply.data.section?.type === 'mcq' || reply.data.section?.type === 'multiple')) reply.data.sectionAttempt.answer = '';
            setInitValues(reply.data.sectionAttempt ? reply.data.sectionAttempt : { answer: (!(reply.data.section?.type === 'mcq' || reply.data.section?.type === 'multiple') ? '' : null) });
            updateValidationSchema(reply.data.section);
            setTimeout(() => {
              if ((document.getElementById('totalPoints') as HTMLElement)) {
                (document.getElementById('totalPoints') as HTMLElement).classList.remove('animate__heartBeat');
                //(document.getElementById('plusFive') as HTMLElement).style.display = 'inline-block'
                setTimeout(() => {
                  (document.getElementById('totalPoints') as HTMLElement).classList.add('animate__heartBeat');
                }, 100)
              }
            }, 100)
            setTimeout(() => {
              (document.getElementById('section') as HTMLElement).style.display = 'block';
              (document.getElementById('footer') as HTMLElement).style.display = 'block';
            }, 10)
          }
        } else {
          //TODO
        }
        console.log("here1")
        setIsLoading(false);
        DisplayLoader(false, '')
      }, (error) => {
        setIsLoading(false);
        DisplayLoader(false, '')
        navigate('/')
        //reject(error)//TODO
      });
  }
  const copyLink = () => {
    navigator.clipboard.writeText(window.location.origin + '/live/' + currentUser?.id);
    let newCopyStatus = true;
    setCopyStatus(newCopyStatus)
  }
  const checkAnswer = (values: FormikValues) => {
    DisplayLoader(true, '')
    console.log("here")
    axios.post(process.env.REACT_APP_API_URL + '/check-answer/' + attemptId + '/' + section._id, values).then((reply) => {
      if (reply?.data) {
        if (reply.data.result === true) {
          (document.getElementById('plusFive') as HTMLElement).style.display = 'inline-block'
          new Audio('/audio/win.mp3').play();
          setTimeout(() => {
            (document.getElementById('plusFive') as HTMLElement).style.display = 'none'
          }, 1000)
        } else if (reply.data.result === false) {
          new Audio('/audio/lose.mp3').play();
        }
        getSection(true);
        if (reply.data.result !== null && document.getElementById('totalPoints')) {

        }
      } else {
        //TODO
      }
      DisplayLoader(false, '')
    }, (error) => {
      DisplayLoader(false, '')
      //reject(error)//TODO
    });
  }
  return (
    <>
      {showRegistrationForm && !isLoading && <div className='p-2 pb-0 ms-3 me-3 mt-3'>
        <h3 className='text-center'>Your Details</h3>
        {Registration(updateRegistrationValues)}
      </div>}
      {!showRegistrationForm && !isLoading && <div className='border-top border-dark border-2 p-2 pb-0 ms-3 me-3' style={{ marginBottom: "100px" }}>
        <div className='rounded p-2 text-center'>
          <img
            className='mw-100 mh-200px' alt='olearno' src={'/media/illustrations/dozzy-1/1.png'}
            style={{ position: 'fixed', right: '0', opacity: '0.5', zIndex: -1, bottom: '30px' }}
          />
          <img alt='olearno' src={'/media/icons/duotune/abstract/abs026.svg'}
            style={{ position: 'absolute', left: '0', zIndex: -1, top: '80px' }}
          />

          {currentUser?.role !== "student" && <div>
            <button className='btn btn-info mb-1 float-end p-2' data-bs-toggle="modal" data-bs-target="#reportsModal" onClick={getReports}>Reports</button>
            <button className='btn btn-info mb-1 float-end me-2 p-2' onClick={showThis}>Show This</button>
            <button className='btn btn-info mb-1 float-end me-2 p-2' onClick={copyLink}>{copyStatus ? 'Copied' : 'Copy Link'}</button>
          </div>
          }
          <h4>
            {challenge.heading}
          </h4>
          <div className='text-center mb-12'>
            <div className='d-inline-block text-center text-dark shadow-lg rounded p-4 pb-1 mb-5 float-start'>
              <b>{currentSectionNo + 1}</b>/{challenge.totalSections}<br />
              <h6>Section</h6>
            </div>
          </div>
        </div>
        {section.type && !isLoading && <Formik enableReinitialize={true} validateOnChange={false} validateOnBlur={false}
          validationSchema={validationSchema} initialValues={initValues} onSubmit={checkAnswer}>
          {({ errors, touched, values, setFieldValue, setErrors }) => (
            <Form >
              <hr className='m-2' />
              <div className='col-12 d-inline-block p-1 align-top'>
                <div>

                  <div id='section' style={{ fontFamily: 'Montserrat' }}>
                    {ViewSection(section, true, 1, errors, section, setFieldValue, ((section.type == 'pair' || section.type == 'imageselect' || section.type == 'crossword') ? { ...section, ...{ correct: true } } : ((section.type == 'blanks' || section.type == 'blanks-any' || section.type == 'mindmapquestion' || section.type == 'dragdrop' || section.type == 'verticalsequence' || section.type == 'horizontalsequence' || section.type == '7lives' || section.type == 'short') ? { ...section, ...{ correct: false } } : section)))}
                  </div>
                  <div id='footer'>
                    <button disabled={currentSectionNo === 0} type='button' className='btn btn-dark shadow float-start me-2 mt-4' onClick={() => { setCurrentSectionNo(currentSectionNo - 1) }}>Prev</button>
                    <button type='button' disabled={currentSectionNo === challenge.totalSections - 1} className='btn btn-dark shadow float-end mt-4' onClick={() => { setCurrentSectionNo(currentSectionNo + 1) }}>Next</button>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>}
      </div>}
      <div className="modal fade" id="reportsModal" aria-labelledby="reportsModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="reportsModalLabel">Reports</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <table className='table table-bordered'>
                <thead>
                  <tr><th>Name</th><th>Result</th><th>Points</th><th>Total</th></tr>
                </thead>
                <tbody>
                  {attempts.map((attempt: any) => {
                    return <><tr><td>{attempt.name}
                    </td>
                      <td>{attempt.sections[section._id] && Object.keys(attempt.sections[section._id]).length ? (attempt.sections[section._id]['correct'] ? <span className={'p-2 bg-success text-light'}>Correct</span> : <span className={'p-2 bg-danger text-light'}>Wrong</span>) : '-'}</td><td>{attempt.sections[section._id] ? attempt.sections[section._id]['points'] : '-'}</td><td>{attempt.totalPoints}</td>
                    </tr>
                      <tr><td colSpan={4}><div>Answer - {attempt.sections[section._id] && <span>
                        {(section.type == 'short' || section.type == 'long' || section.type == 'numerical') && <span><KaTeXRenderer>{attempt.sections[section._id].attempt || ''}</KaTeXRenderer> </span>}
                        {(section.type == 'dragdrop') && <div className={'row col-12 m-1 mt-2 justify-content-center align-bottom rounded'} style={{ pointerEvents: 'none' }}>
                          <DragDrop setFieldValue={() => { }} itemsIn={[...[
                            { id: 'question', items: [] }
                          ],
                          ...section.options.map((opt: string[]) => opt[1]).filter((opt: string) => opt != '').map((opt: string) => { return { id: opt, items: section.options.map((opt: string[]) => opt[0]).filter((opt1: string, index: number) => { return opt1 != '' && isMatch(index, opt, attempt.sections[section._id].answer, section.options) }).map((opt: string, index: number) => { return { id: index.toString(), name: opt, index: index } }) } })]}></DragDrop>
                        </div>}
                        {(section.type == 'verticalsequence') && <div style={{ pointerEvents: 'none' }}><SequenceVertical setFieldValue={(() => { })} itemsIn={attempt.sections[section._id].answer.map((option: string, index: number) => {
                          return ({ id: index.toString(), name: option, index: index })
                        })}></SequenceVertical></div>}
                        {(section.type == 'horizontalsequence') && <div style={{ pointerEvents: 'none' }}><SequenceHorizontal setFieldValue={() => { }} itemsIn={attempt.sections[section._id].answer.map((option: string, index: number) => {
                          return ({ id: index.toString(), name: option, index: index })
                        })}></SequenceHorizontal></div>}
                        {(section.type == '7lives') && <>
                          {attempt.sections[section._id].answer.split('').map((l: string) => { return <>{(l == '#' ? 'CLUE' : l) + ','}</> })}
                        </>}
                        {(section.type == 'crossword') && <>
                        </>}
                        {(section.type == 'imageselect') && <>{/*<div className='animate__animated animate__bounceInUp' style={{maxHeight: '200px'}}>
                              <AnnotatableImageView
                                section={section}
                                disabled={true}
                                values={{}}
                                attempt={answer}
                                setFieldValue={()=>{}} />
                          </div>*/}</>}
                        {(section.type == 'matrix') && <span>{section?.options?.map((option: any, index: number) => {
                          return (
                            <>
                              {option[0] && option[0].trim() != '' && <tr><td className='m-2'><b>{(index + 1)}.</b></td>
                                {section?.options?.map((option: any, index1: number) => {
                                  return <>{option[1] && option[1].trim() != '' && <td><input className='m-2' type='checkbox' name='answer' checked={attempt.sections[section._id].answer.indexOf(index.toString() + '-' + index1.toString()) != -1} value={index.toString() + '-' + index1.toString()} style={{ height: "15px", width: "15px" }} disabled={true} /></td>}</>
                                })}
                              </tr>}
                            </>
                          )
                        })}</span>}
                        {(section.type == 'blanks' || section.type == 'blanks-any') && <span>{attempt.sections[section._id].answer.map((a: string) => { return <KaTeXRenderer>{(a || '') + ','}</KaTeXRenderer> })}</span>}
                        {(section.type == 'multiple') && <span>{attempt.sections[section._id].answer.map((a: string) => { return <KaTeXRenderer>{(section.options[a] || '') + ','}</KaTeXRenderer> })}</span>}
                        {(section.type == 'mcq') && <KaTeXRenderer>{section.options[attempt.sections[section._id].answer] || ''}</KaTeXRenderer>}
                      </span>}
                        {!attempt.sections[section._id] && <span>-</span>}</div></td></tr></>
                  })}
                </tbody>
              </table>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary float-end" data-bs-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export { PresentLive }
