import axios, { AxiosResponse } from 'axios';
import * as Yup from 'yup'
import { FC, useState, useEffect } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Form, Formik, FormikValues, Field } from 'formik'
import { Section, sectionInit, ValidationSchema } from '../../models/Section'
import { ViewSection } from '../../modules/components/display/ViewSection';
import { ErrorDisplay } from '../../modules/components/common/ErrorDisplay';
import { DisplayLoader } from '../../utils/loader';
import { number } from 'yup';
import { useAuth } from '../../modules/auth';
import { Modal } from 'react-bootstrap';
import { getUserByToken } from '../../modules/auth/core/_requests';
import { systemSort } from '../../utils/systemSort';
import { Registration } from '../registration/Registration';
import { initializeApp } from "firebase/app";
import { getDatabase, ref, set, onValue } from "firebase/database";
const firebaseConfig = {
  apiKey: "AIzaSyByPMDxjiibLupiXvlbubCSlsabPFurpXA",
  authDomain: "olearno-4b1fb.firebaseapp.com",
  databaseURL: "https://olearno-4b1fb-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "olearno-4b1fb",
  storageBucket: "olearno-4b1fb.appspot.com",
  messagingSenderId: "990749151543",
  appId: "1:990749151543:web:8652bce69adc94c82acab2",
  measurementId: "G-PY2RTFHBE3"
};
const app = initializeApp(firebaseConfig);
const database = getDatabase(app);
type ApiParams = { id: string; sectionNo: string };
const Live: FC = () => {
  const { id, sectionNo } = useParams<ApiParams>();
  const { saveAuth, currentUser, setCurrentUser } = useAuth()
  const navigate = useNavigate();
  let [isLoading, setIsLoading] = useState<boolean>(true);
  let [challenge, setChallenge] = useState<any>({ sections: [] });
  const [secondsLeft, setSecondsLeft] = useState(0);
  const [intervalId, setIntervalId] = useState<NodeJS.Timer | null>(null);
  let [section, setSection] = useState<any>({});
  let [showRegistrationForm, setShowRegistrationForm] = useState<boolean>(false);
  let [attempt, setAttempt] = useState<any>({});
  let [attemptId, setAttemptId] = useState<any>('');
  let [currentSectionDetails, setCurrentSectionDetails] = useState<any>({});
  let [initValues, setInitValues] = useState<Section>(sectionInit);
  let [validationSchema, setValidationSchema] = useState<any>({});
  useEffect(() => {
    const variableRef = ref(database, 'live/' + id);
    onValue(variableRef, (snapshot) => {
      const data = snapshot.val();
      console.log("Received update:", data);
      setCurrentSectionDetails(data)
      getSection(data.challengeId, data.sectionPosition, data.sessionId);
    });
  }, []);
  const updateRegistrationValues = async (values: FormikValues) => {
    console.log("here")
    let input: any = { name: values.name, institutionId: challenge.institutionId, role: 'student' }
    let profileDetails = { ...values }
    delete profileDetails.name;
    input.profileDetails = profileDetails
    try {
      const { data: auth } = await axios.post(process.env.REACT_APP_API_URL + '/create-user-without-login/', input)
      console.log(auth)
      saveAuth(auth)
      const { data: user } = await getUserByToken(auth.api_token)
      setCurrentUser(user)
      // getSection();
    } catch (e) {
      //TODO
    }


  }
  const updateValidationSchema = (values: Section) => {
    setValidationSchema(ValidationSchema(values, true));
  }
  const getSection = (challengeId: string, sectionPosition: number, sessionId: number, shouldNotReload?: boolean) => {
    DisplayLoader(true, '')
    if (!shouldNotReload) setIsLoading(true);
    axios.post(process.env.REACT_APP_API_URL + '/get-section/',
      { challengeInstanceId: challengeId, type: 'lesson', sectionPosition: sectionPosition, sessionId: sessionId, purpose: 'live' }).then((reply) => {
        console.log(reply.data)
        if (reply?.data) {
          if (reply.data.register) {
            setChallenge(reply.data.challenge);
            setShowRegistrationForm(true)
          } else if (reply.data.redirect) {
            window.location.pathname = reply.data.redirect;
          } else {
            setShowRegistrationForm(false);
            if (reply.data.section?.type === 'pair') {
              reply.data.section.jumbledOptions = systemSort([...reply.data.section.options]);
            }
            console.log(reply.data)
            setChallenge(reply.data.challenge);
            setSection(reply.data.section);
            setAttempt(reply.data.sectionAttempt);
            setAttemptId(reply.data.attemptId);
            setTimeout(()=>{
              let sectionAttempt = {...reply.data.sectionAttempt}
              if (reply.data.sectionAttempt && !reply.data.sectionAttempt.answer && !(reply.data.section?.type === 'mcq' || reply.data.section?.type === 'multiple')) sectionAttempt.answer = '';
              setInitValues(reply.data.sectionAttempt ? sectionAttempt : { answer: (!(reply.data.section?.type === 'mcq' || reply.data.section?.type === 'multiple') ? '' : null) });  
            },100)
            updateValidationSchema(reply.data.section);
            setTimeout(() => {
              if ((document.getElementById('totalPoints') as HTMLElement)) {
                (document.getElementById('totalPoints') as HTMLElement).classList.remove('animate__heartBeat');
                //(document.getElementById('plusFive') as HTMLElement).style.display = 'inline-block'
                setTimeout(() => {
                  (document.getElementById('totalPoints') as HTMLElement).classList.add('animate__heartBeat');
                }, 100)
              }
            }, 100)
            setTimeout(() => {
              (document.getElementById('section') as HTMLElement).style.display = 'block';
              (document.getElementById('footer') as HTMLElement).style.display = 'block';
            }, 10)
          }
        } else {
          //TODO
        }
        console.log("here1")
        setIsLoading(false);
        DisplayLoader(false, '')
      }, (error) => {
        setIsLoading(false);
        DisplayLoader(false, '')
        navigate('/')
        //reject(error)//TODO
      });
  }

  const checkAnswer = (values: FormikValues) => {
    DisplayLoader(true, '')
    console.log("here")
    let inputValues = {...values, ...{sessionId: currentSectionDetails.sessionId}}
    axios.post(process.env.REACT_APP_API_URL + '/check-answer/' + attemptId + '/' + section._id, inputValues).then((reply) => {
      if (reply?.data) {
        if (reply.data.result === true) {
          (document.getElementById('plusFive') as HTMLElement).style.display = 'inline-block'
          new Audio('/audio/win.mp3').play();
          setTimeout(() => {
            (document.getElementById('plusFive') as HTMLElement).style.display = 'none'
          }, 1000)
        } else if (reply.data.result === false) {
          new Audio('/audio/lose.mp3').play();
        }
        getSection(currentSectionDetails.challengeId, currentSectionDetails.sectionPosition, currentSectionDetails.sessionId, true);
        if (reply.data.result !== null && document.getElementById('totalPoints')) {

        }
        //setChallenge(reply.data.challenge);
        //setSection(reply.data.section);
        //updateValidationSchema(reply.data.section);
      } else {
        //TODO
      }
      DisplayLoader(false, '')
    }, (error) => {
      DisplayLoader(false, '')
      //reject(error)//TODO
    });
  }

  return (
    <>
      {showRegistrationForm && !isLoading && <div className='p-2 pb-0 ms-3 me-3 mt-3'>
        <h3 className='text-center'>Your Details</h3>
        <Formik enableReinitialize={true} validateOnChange={false} validateOnBlur={false}
          validationSchema={Yup.object({
            name: Yup.string().required("This field is required"),
          })} initialValues={{}} onSubmit={(values) => { console.log("values"); updateRegistrationValues(values) }}>
          {({ errors, touched, values, setFieldValue, setErrors }) => (
            <Form >
              <div>
                <label className='form-label fw-bolder text-dark fs-6'>Full Name</label>
                <Field type='text' name='name' className='form-control form-control mb-1' />
                {ErrorDisplay('name', errors)}
              </div>
              <button className='btn btn-primary float-end'>Submit</button>
            </Form>)}
        </Formik>
      </div>}
      {!showRegistrationForm && !isLoading && <div className='border-top border-dark border-2 p-2 pb-0 ms-3 me-3' style={{ marginBottom: "100px" }}>
        <div className='rounded p-2 text-center'>
          <img
            className='mw-100 mh-200px' alt='olearno' src={'/media/illustrations/dozzy-1/1.png'}
            style={{ position: 'fixed', right: '0', opacity: '0.5', zIndex: -1, bottom: '30px' }}
          />
          <img alt='olearno' src={'/media/icons/duotune/abstract/abs026.svg'}
            style={{ position: 'absolute', left: '0', zIndex: -1, top: '80px' }}
          />

          {currentUser?.role !== "student" && <div>
            <Link type='button' to={'/composite-reports/' + id} className='btn btn-info m-1 float-end p-2 shadow-lg'>
              <i className="fa fa-chart-line"></i><small>Reports</small>
            </Link>
            <Link className='btn btn-dark mb-1 float-start' to={'/create-module/' + challenge._id}>Edit This Module</Link>
          </div>
          }
          <h4>
            {challenge.heading}
          </h4>

        </div>
        {section.type && !isLoading && <Formik enableReinitialize={true} validateOnChange={false} validateOnBlur={false}
          validationSchema={validationSchema} initialValues={initValues} onSubmit={checkAnswer}>
          {({ errors, touched, values, setFieldValue, setErrors }) => (
            <Form >
              <hr className='m-2' />
              <div className='col-12 d-inline-block p-1 align-top'>
                <div>
                  <div className='text-center mb-12'>
                    {window.location.pathname.split("/")[1] !== 'test' && <><div id='totalPoints' className='d-inline-block float-end bg-success text-light text-center shadow-lg rounded p-4 pb-1 mb-5 animate__animated animate__slow'>
                      <b>{challenge.currentPoints}</b><br />
                      <h6 className='text-light'>Total Points</h6>
                    </div>
                      <div id="plusFive" className="rounded p-3 float-end bg-info text-light text-center animate__animated animate__fadeOutUp animate__slow" style={{ display: 'none', position: 'relative', top: "50px", right: "-75px", transform: 'translateY(100px)' }}>+{attempt?.points}</div>
                    </>}
                  </div>
                  <div id='section' style={{ fontFamily: 'Montserrat' }}>
                    {ViewSection(section, attempt && Object.keys(attempt).length > 0 && ((attempt.correct !== null) && window.location.pathname.split('/')[1] !== 'test') ? true : false, 1, errors, values, setFieldValue, (!attempt || Object.keys(attempt).length == 0) ? null : attempt, true, checkAnswer)}
                  </div>
                  <div id='footer'>
                    {attempt && (attempt.answer || (section.type === 'pair' && attempt.correct) || (section.type === 'crossword' && attempt.correct)) && section.type !== 'long-text' &&
                      <div className='mt-3'>
                        {attempt.correct && <div className='p-5 bg-success text-center rounded animate__animated animate__bounceInUp' style={{ animationDelay: '0.05s' }}><h4 className='text-white'>Correct</h4></div>}
                        {attempt.correct === false && <div className='p-5 bg-danger text-center rounded animate__animated animate__bounceInUp' style={{ animationDelay: '0.05s' }}><h4 className='text-white'>Wrong</h4></div>}
                      </div>
                    }
                    {!(attempt && attempt.answer) && section.type !== 'pair' && section.type !== '7lives' && section.type !== 'crossword' && section.type !== 'slide' && section.type !== 'mindmapactivity' && section.type !== 'mindmapslide' && section.type !== 'simulation' &&
                      <div className="d-grid gap-2">
                        <button type='submit' className='btn btn-block btn-dark m-3 animate__animated animate__bounceInUp' style={{ animationDelay: '0.75s' }}>Submit</button>
                      </div>
                    }
                    {/* <button disabled={currentSectionNo === 0} type='button' className='btn btn-dark shadow float-start me-2 mt-4' onClick={() => { setCurrentSectionNo(currentSectionNo - 1) }}>Prev</button>
                    <button type='button' disabled={currentSectionNo === challenge.totalSections - 1} className='btn btn-dark shadow float-end mt-4' onClick={() => { setCurrentSectionNo(currentSectionNo + 1) }}>Next</button> */}
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>}
      </div>}
    </>
  )
}

export { Live }
